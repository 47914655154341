<template>
  <div class="contaner">
    <div @click="mineMessage" class="messageTitle12">
      <img class="messageIcon" src="../../../assets/userPhone1.png" />
      <div>联系客服</div>
    </div>
    <!-- 激活股东 -->
    <div class="content" v-if="tableData.activate.audit_type==2">
      <!-- <div>
                <div v-if="tableData.activate.audit_state==1">信息正在审核中！流程如下</div>
                <div v-if="tableData.activate.audit_state==3" style="color:#FC3757FF">信息审核失败！</div>
                <div class="colorRed">信息激活</div>
                <div class="colorRed">激活身份--股东</div>
            </div>

            <div>
                <div class="colorRed">回寄信息</div>
                <div style="display:flex">
                    <div>及时邮寄所有合同资料！</div>
                    <div class="colorText" @click="lookAddress">查看信息,填写单号></div>
                </div>
            </div>
            <div v-if="tableData.activate.audit_state==2" class="colorRed">平台审核</div>
            <div v-if="tableData.activate.audit_state==2">
                <div class="colorRed">信息更新,等待邮寄</div>
                <div @click="logistics1" class="colorText">查看物流></div>
            </div>
            <div v-if="tableData.activate.audit_state==3" style="color:#FC3757FF;margin-bottom:20px">已结束</div>
            <div v-if="tableData.activate.audit_state==3">失败原因：{{tableData.activate.audit_remark?tableData.activate.audit_remark[0]:''}}</div>
            <div v-if="tableData.activate.audit_state==2&&tableData.activate.shipments.length==2" :class="tableData.activate.audit_state==2?'colorRed':''">已完成</div> -->
      <div class="flow_content">
        <div class="flow_list">
          <div class="flow_header">
            <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E">1</div>
            <div class="line_flow" style="border:0.5px dashed #FF5E5E"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" style="color:#FF5E5E">信息提交</div>
              <img class="flow_imgBtn" src="../../../assets/successIcon2.png" />
              <!-- <div class="flow_text1" v-if="queryList.status&&tableData.info_state==1" @click="lookBtn">点击填写股份信息>></div> -->
            </div>
            <div class="flow_text1" style="color:#FF5E5E">激活身份--股东</div>
            <!-- <div class="flow_text1" v-if="!queryList.status&&tableData.info_state==1" @click="lookBtn">重新填写信息>></div> -->
          </div>
        </div>
        <div class="flow_list">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.activate.audit_state>=0?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">2</div>
            <div class="line_flow" :style="tableData.activate.audit_state>=0?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.activate.audit_state>=0?'color:#FF5E5E':''">平台受理</div>

              <img v-if="tableData.activate.audit_state==3&&tableData.activate.shipments.length==0" class="flow_imgBtn" src="../../../assets/failIcon.png" />
              <img v-if="tableData.activate.audit_state==1||tableData.activate.audit_state==2" class="flow_imgBtn" src="../../../assets/successIcon2.png" />
            </div>
            <div class="flow_text1" v-if="tableData.activate.audit_state==0&&tableData.activate.shipments.length==0" style="color:#000">等待平台审核</div>
            <div class="flow_text1"  v-if="tableData.activate.audit_state==1" style="color:#000">平台初审已通过</div>
            <div class="flow_text1"  v-if="tableData.activate.audit_state==3" style="color:#FF5E5E">平台已拒绝</div>
          </div>
        </div>
        <!-- <div class="flow_list"   v-if="tableData.audit_state!=3&&tableData.info_state!=3">
                <div class="flow_header">
                    <div class="flow_box" :style="tableData.info_state>=2?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">3</div>
                    <div class="line_flow" :style="tableData.info_state>=2?'border:0.5px dashed #FF5E5E':''"></div>
                </div>
                <div>
                    <div style="display:flex">
                        <div class="flow_title" :style="tableData.info_state>=2?'color:#FF5E5E':''">合同邮寄</div>
                        <img class="flow_imgBtn" v-if="tableData.info_state>=2" src="../../../assets/successIcon2.png"/>
                    </div>
                    <div class="flow_text1" style="color:#000">等待邮寄，平台整理物料中！</div>
                    <div class="flow_text1" @click="logistics1" v-if="tableData.info_state==2&&tableData.shipments.length>0">查看物流></div>
                </div>
            </div> -->
        <div class="flow_list" v-if="!(tableData.activate.audit_state==3&&tableData.activate.shipments.length==0)&&tableData.activate.audit_shipments==1">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.activate.audit_state>=1?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">3</div>
            <div class="line_flow" :style="tableData.activate.audit_state>=1?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.activate.audit_state>=1?'color:#FF5E5E':''">合同回寄</div>
              <img class="flow_imgBtn" v-if="tableData.activate.shipments.length>0" src="../../../assets/successIcon2.png" />
            </div>
            <div class="flow_text1" style="color:#000" v-if="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==0">请邮寄回全部的老合同或者期权证</div>
            <div class="flow_text1" style="color:#FF5E5E" v-if="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==1">已完成回寄</div>
            <div class="flow_text1" v-if="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==0" @click="lookAddress">查看地址,填写单号</div>
            <div class="flow_text1" v-if="tableData.activate.audit_state>=1&&tableData.activate.shipments.length>0" @click="lookAddress">查看地址</div>
          </div>
        </div>
        <div class="flow_list" v-if="tableData.activate.audit_state!=3">
          <div class="flow_header"  v-if="tableData.activate.audit_shipments==0">
            <div class="flow_box" :style="tableData.activate.audit_state>=1?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">{{tableData.activate.audit_shipments==1?'4':'3'}}</div>
            <div class="line_flow" :style="tableData.activate.audit_state>=1?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
           <div class="flow_header" v-else>
            <div class="flow_box" :style="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==1?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">{{tableData.activate.audit_shipments==1?'4':'3'}}</div>
            <div class="line_flow" :style="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==1?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
          <div>
             <div style="display:flex" v-if="tableData.activate.audit_shipments==0">
              <div class="flow_title" :style="tableData.activate.audit_state>=1?'color:#FF5E5E':''">信息确认{{tableData.activate.audit_shipments==1?",等待邮寄":""}}</div>
              <img class="flow_imgBtn" v-if="tableData.activate.audit_state==2" src="../../../assets/successIcon2.png" />
            </div>
            <div style="display:flex" v-else>
              <div class="flow_title" :style="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==1?'color:#FF5E5E':''">{{tableData.activate.audit_shipments==1?"信息确认,等待邮寄":"物料发放"}}</div>
              <img class="flow_imgBtn" v-if="tableData.activate.audit_state==2&&tableData.activate.shipments.length==1" src="../../../assets/successIcon2.png" />
            </div>
            <div v-if="tableData.activate.audit_shipments==0">
                <div class="flow_text1" @click="logistics1" v-if="tableData.activate.shipments.length>0">查看物流></div>
               <div class="flow_text1" v-if="tableData.activate.audit_state==1&&tableData.activate.shipments.length==0">等待信息确认，生成数据</div>
            </div>
            <div v-else>
                <div class="flow_text1" @click="logistics1" v-if="tableData.activate.shipments.length>1">查看物流></div>
               <div class="flow_text1" v-if="tableData.activate.audit_state>=1&&tableData.activate.shipments.length==1">等待信息确认，物料发放</div>
            </div>
          </div>
        </div>
        <div class="flow_list" v-if="tableData.activate.audit_state!=3&&tableData.activate.info_state!=3">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.activate.audit_state==2?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">{{tableData.activate.audit_shipments==1?'5':'4'}}</div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.activate.audit_state==2?'color:#FF5E5E':''">已完成</div>
              <img class="flow_imgBtn" v-if="tableData.activate.audit_state==2" src="../../../assets/successIcon2.png" />
            </div>
          </div>
        </div>

        <div class="flow_list" v-if="tableData.activate.audit_state==3">
          <div class="flow_header">
            <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E">3</div>
            <!-- <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E" v-if="tableData.activate.audit_state==3&&tableData.activate.shipments.length==0">3</div> -->
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" style="color:#FF5E5E">已结束</div>
            </div>
            <div v-if="tableData.activate.audit_state==3">
              <div class="flow_text1">审核已拒绝</div>
              <div class="flow_text1" v-if="tableData.activate.audit_remark">原因:{{tableData.activate.audit_remark?tableData.activate.audit_remark[0]:''}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 激活会员 -->
    <div class="content" v-else>
      <div class="flow_content">
        <div class="flow_list">
          <div class="flow_header">
            <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E">1</div>
            <div class="line_flow" style="border:0.5px dashed #FF5E5E"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" style="color:#FF5E5E">信息提交</div>
              <img class="flow_imgBtn" src="../../../assets/successIcon2.png" />

            </div>
            <div class="flow_text1" style="color:#FF5E5E">激活身份--工会会员</div>
            <!-- <div class="flow_text1" v-if="tableData.info_state==1" @click="detailsInfo(tableData.audit_id)">重新填写信息>></div> -->
          </div>
        </div>
        <div class="flow_list">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.activate.audit_state>1?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">2</div>
            <div class="line_flow" :style="tableData.activate.audit_state>1?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.activate.audit_state>1?'color:#FF5E5E':''">平台受理,信息确认</div>
              <img v-if="tableData.activate.audit_state==2" class="flow_imgBtn" src="../../../assets/successIcon2.png" />
              <img v-if="tableData.activate.audit_state==3" class="flow_imgBtn" src="../../../assets/failIcon.png" />
            </div>
            <div v-if="tableData.activate.audit_state==3">
              <div class="flow_text1">审核失败</div>
              <div class="flow_text1">原因:{{tableData.activate.audit_remark[0]}}</div>
            </div>
            <div v-if="tableData.activate.audit_state==2" class="flow_text1" style="color:#000">资料审核通过</div>
          </div>
        </div>
        <div class="flow_list" v-if="tableData.activate.audit_state==3">
          <div class="flow_header">
            <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E">3</div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" style="color:#FF5E5E">已结束</div>
            </div>
          </div>
        </div>
        <div class="flow_list" v-if="tableData.activate.audit_state!=3">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.activate.audit_state==2?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">3</div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.activate.audit_state==2?'color:#FF5E5E':''">已完成</div>
              <img class="flow_imgBtn" v-if="tableData.activate.audit_state==2" src="../../../assets/successIcon2.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
                <div v-if="tableData.activate.audit_state==1">信息正在审核中！流程如下</div>
                <div v-if="tableData.activate.audit_state==2">信息已通过！流程如下</div>
                <div v-if="tableData.activate.audit_state==3">信息已失败！流程如下</div>
                <div class="colorRed">信息激活</div>
                <div class="colorRed">激活身份--工会会员</div>
            </div>
            <div class="colorRed">平台审核</div>
            <div v-if="tableData.activate.audit_state==2">平台审核已通过</div>
            <div v-if="tableData.activate.audit_state==3">平台审核未通过</div>
            <div v-if="tableData.activate.audit_state==3">
                <div>原因：</div>
            </div>
            <div v-if="tableData.activate.audit_state==2&&tableData.activate.shipments.length==2" :class="tableData.activate.audit_state==2?'colorRed':''">已完成</div> -->
    </div>

    <div class="flow_popbox" v-if="popShow">
      <div class="flow_pop1" v-if="popShow1">

      </div>

      <div class="flow_popbox">
        <div class="flow_pop1" v-if="popShow1">
          <img @click="submitBtn" style="width:10px;height:10px;" src="../../../assets/cloneBtn2.png" />
          <div class="pop1">
            <div v-if="tableData.activate.shipments.length>0&&!tableData.activate.shipments[0]==false" class="receiver">
              
              <div style="display:flex;margin-bottom:20px" v-if="tableData.activate.shipments.length>0"><span class="title_pop">寄出时间:</span>{{tableData.activate.shipments.length>0?tableData.activate.shipments[0].create_time:''}}</div>
              <div style="display:flex;margin-bottom:20px" v-if="tableData.activate.shipments.length>0"><span class="title_pop">快递公司:</span>{{tableData.activate.shipments.length>0?tableData.activate.shipments[0].shipments_info.company:''}}</div>
              <div style="display:flex;margin-bottom:20px" v-if="tableData.activate.shipments.length>0"><span class="title_pop">快递单号:</span><span class="address_text_flow">{{tableData.activate.shipments.length>0?tableData.activate.shipments[0].shipments_info.order_sn:''}}</span></div>
            </div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件人:</span>刘近</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件人电话:</span>15623988220</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件地址:</span><span class="address_text_flow">武汉市江岸区金桥大道特115号长江传媒大厦13楼</span></div>
            <div v-if="!tableData.activate.shipments[0]">
              <div style="color:#FF5E5E">*请将收到的全部物料按要求填写完毕寄回。</div>

              <div class="formList_flow">
                <div class="offine_title_flow">快递公司:</div>
                <input v-model="dataForm.company.value" />
              </div>
              <div class="formList_flow">
                <div class="offine_title_flow">快递单号:</div>
                <input v-model="dataForm.order_sn.value" />
              </div>
              <div class="formList_flow">
                <div class="offine_title_flow">备注:</div>
                <textarea v-model="dataForm.message.value"></textarea>
              </div>
              <div class="submitBtn" @click="companyBtn">提交</div>
            </div>
          </div>
        </div>
        <logistics v-if="popShow3" @submitBtn="submitBtn" :tableData="tableData" :dataList="dataList"></logistics>
      </div>
    </div>

    <toast v-if="toastShow" :config="configData"></toast>

  </div>
</template>
<script>
import * as apiCommon from "@/api/common";
import toast from "@/components/toast";
// import wx from "weixin-js-sdk";
import logistics from "@/components/logistics";
import { getShipments_api, shipments_api } from "@/api/admin";
export default {
  data() {
    return {
      queryList: null,
      tableData: {
        activate: {},
        // shipments:[]
      },
      popShow: false,
      popShow1: false,
      popShow2: false,
      popShow3: false,
      companyShow:true,
      dataForm: {
        company: {
          value: "",
          title: "快递公司",
          text: "请输入快递公司",
          color: "#DEB77A"
        },
        order_sn: {
          value: "",
          title: "快递单号",
          text: "请输入快递单号",
          color: "#DEB77A"
        },
        message: {
          value: "",
          title: "备注",
          text: "请输入备注",
          color: "#DEB77A"
        }
      },
      configData: {
        title: ""
      },
      toastShow: false,
      dataList: {
        activate: {}
      }
    };
  },
  components: {
    toast,
    logistics
  },
  async created() {
    document.title = "身份激活";
    this.queryList = this.$route.query;
    await this.getDetails();
  },
  methods: {
    lookAddress() {
      this.popShow = true;
      this.popShow1 = true;
    },
    logistics1() {
      this.popShow = true;
      this.popShow3 = true;
      this.$forceUpdate();
    },
    submitBtn() {
      this.popShow = false;
      this.popShow1 = false;
      this.popShow2 = false;
      this.popShow3 = false;
      this.$forceUpdate();
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../../lador/service");
    },
    async getDetails() {
      let res = await apiCommon.getUserInfo_api();
      if (res.code == 0) {
        console.log(res.data);
        this.tableData = res.data;
        // console.log(this.tableData.activate.shipments[0],"1111")
        // if(this.tableData.activate.shipments.length==0){
          // Object.keys(this.tableData.activate.shipments)
        // }
        if (this.tableData.activate.shipments.length > 0) {
          this.logistics();
        }
        this.$forceUpdate();
      }
    },
    logistics() {
      // 是否邮寄
      if (this.tableData.activate.audit_shipments == 1) {
        if (this.tableData.activate.shipments.length == 2) {
          this.tableData.activate.shipments[1].shipments_info.logistic = 0;
          getShipments_api(
            this.tableData.activate.shipments[1].shipments_info
          ).then(res => {
            console.log(res.data);
            if (res.code == 0) {
              this.dataList = res.data;
              if (this.dataList) {
                this.dataList.Traces.reverse();
              }
              console.log(this.dataList);
              this.$forceUpdate();
            }
          });
        }
      }else{
          if (this.tableData.activate.shipments.length == 1) {
          this.tableData.activate.shipments[0].shipments_info.logistic = 0;
          getShipments_api(
            this.tableData.activate.shipments[0].shipments_info
          ).then(res => {
            console.log(res.data);
            if (res.code == 0) {
              this.dataList = res.data;
              if (this.dataList) {
                this.dataList.Traces.reverse();
              }
              console.log(this.dataList);
              this.$forceUpdate();
            }
          });
        }
      }
    },
    // 发货
    companyBtn() {
      if(!this.companyBtn){
          this.toastShow = true;
          this.configData.title = "请勿多次点击";
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          return false;
      }
      this.companyBtn = false
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          return false;
        }
      }
      let data = {
        audit_id: Number(this.queryList.id),
        audit_type: 3,
        shipments_info: {
          company: this.dataForm.company.value,
          order_sn: this.dataForm.order_sn.value,
          message: this.dataForm.message.value
        }
      };
      shipments_api(data).then(res => {
        this.companyBtn = true
        console.log(res.data);
        if (res.code == 0) {
          this.toastShow = true;
          this.configData.title = "操作成功";
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          this.popShow = false;
          this.popShow1 = false;
          this.popShow2 = false;
          this.popShow3 = false;
          location.reload();
        }
      });
    }
  }
};
</script>
<style type="text/css">
.infoBox {
  width: 690px;
  min-height: 400px;
  margin: 300px auto 0;
  background-color: #fff;
  font-size: 25px;
  padding: 50px;
  box-sizing: border-box;
}
.infoBox img {
  width: 200px;
  height: 200px;
  margin-left: 200px;
}
.messageTitle12 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle12 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  margin-top: 20px;
  color: #000;
  position: absolute;
  top: 10px;
  right: 10px;
}

.title_share {
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #deb77a;
  opacity: 1;
  margin-top: 70px;
}

.content {
  margin-left: 75px;
  font-size: 25px;
  color: #000;
  margin-top: 103px;
}
.content > div {
  margin-bottom: 90px;
}
.colorRed {
  color: #fc3757;
  margin: 20px 0;
}
.colorText {
  color: #4b93ffff;
  font-size: 20px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
  margin: 20px 0;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 100px;
}
.line_flow {
  width: 1px;
  border: 1px dashed #737373;
  margin: 0 18px;
  height: 100px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 20px;
  color: #4b93ff;
  margin-top: 15px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
  /* margin-left: 71px */
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.time_text {
  font-size: 20px;
  color: #000;
  margin: 10px 0 20px 10px;
}
.pop3 {
  height: 776px;
  background: #ffffff;
  font-size: 16px;
  color: #707070;
  padding: 20px 20px 20px 50px;
}
.payClass {
  color: #4b93ff;
  font-size: 25px;
  margin-left: 10px;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 100px;
}
.line_flow {
  width: 1px;
  border: 0.5px dashed #737373;
  margin: 0 18px;
  height: 100px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 20px;
  color: #4b93ff;
  margin-top: 15px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 0.5px solid #ff5e5e;
}
.selectLine {
  border: 0.5px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.title_text {
  color: #4b93ff;
  margin: 0px 0 0 20px !important;
  font-size: 24px;
}
.card {
  margin: 20px 0;
}
.success2 {
  width: 24px;
  height: 24px;
  margin: 10px 0 0 5px;
}
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle1 {
  text-align: right;
  margin-right: 20px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
  margin-bottom: 20px;
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.cart {
  /* width: 120px; */
  /* height: 120px */
  /* margin: 190px auto 0; */
  text-align: center;
  margin-top: 50px;
}
.cart img {
  width: 100%;
  height: 100%;
}
.auditInfo {
  margin: 50px 0 0 37px;
}
.content1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%;
}
.steps {
  height: 150px;
  padding-left: 61px;
}
.step_text {
  font-size: 22px;
  margin: 52px 0 0 54px;
}
.van-step--vertical {
  font-size: 22px;
  color: #000000;
}
.van-step--vertical {
  /* height: 200px; */
  line-height: 45px;
}
.title1 {
  color: #ff5e5e;
}
.van-step__icon--active,
.van-step__icon--finish,
.van-step__title--active,
.van-step__title--finish {
  color: #ff5e5e;
}
.submit {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #4b93ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-left: 100px;
  margin-top: 20px;
}
.logistics {
  margin-left: 70px;
}
.cloneBtn2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>